var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "areaBox",
      style: {
        width: _vm.areaInit.areaWidth + "px",
        height: _vm.areaInit.areaHeight + "px",
        left: _vm.areaInit.starX + "px",
        top: _vm.areaInit.starY + "px",
      },
      on: {
        dblclick: function ($event) {
          _vm.editBoxShow = true
        },
        mousedown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "left", 37, $event.key, [
              "Left",
              "ArrowLeft",
            ])
          ) {
            return null
          }
          if ("button" in $event && $event.button !== 0) {
            return null
          }
          $event.stopPropagation()
          return _vm.mouseDown($event)
        },
        mouseup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "left", 37, $event.key, [
              "Left",
              "ArrowLeft",
            ])
          ) {
            return null
          }
          if ("button" in $event && $event.button !== 0) {
            return null
          }
          $event.stopPropagation()
          return _vm.mouseUp($event)
        },
      },
    },
    [
      _c("span", { staticClass: "promptText" }, [
        _vm._v(_vm._s(_vm.promptText)),
      ]),
      _c("div", {
        staticClass: "del",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.del()
          },
        },
      }),
      _c("div", {
        staticClass: "shape",
        on: {
          mousedown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "left", 37, $event.key, [
                "Left",
                "ArrowLeft",
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 0) {
              return null
            }
            $event.stopPropagation()
            return _vm.shapeDown($event)
          },
          mouseup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "left", 37, $event.key, [
                "Left",
                "ArrowLeft",
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 0) {
              return null
            }
            $event.stopPropagation()
            return _vm.mouseUp($event)
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editBoxShow,
              expression: "editBoxShow",
            },
          ],
          staticClass: "editBox",
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("div", { staticClass: "editCase" }, [
            _c("div", { staticClass: "caseTitle" }, [
              _c("span", [_vm._v("热区设置")]),
              _c("div", {
                staticClass: "close",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.editBoxShow = false
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "caseContent" }, [
              _c("div", { staticClass: "urlMethod" }, [
                _c(
                  "div",
                  {
                    class: { active: _vm.urlMessage.methods === 0 },
                    on: {
                      click: function ($event) {
                        _vm.urlMessage.methods = 0
                      },
                    },
                  },
                  [_vm._v(" 选择商品 ")]
                ),
                _c(
                  "div",
                  {
                    class: { active: _vm.urlMessage.methods === 1 },
                    on: {
                      click: function ($event) {
                        _vm.urlMessage.methods = 1
                      },
                    },
                  },
                  [_vm._v(" 输入链接 ")]
                ),
              ]),
              _c("div", { staticClass: "selectBox" }, [
                _c(
                  "div",
                  {
                    staticClass: "openWay",
                    on: {
                      click: function ($event) {
                        _vm.urlMessage.select = "_blank"
                      },
                    },
                  },
                  [
                    _c("div", {
                      class: [
                        "dot",
                        { active: _vm.urlMessage.select === "_blank" },
                      ],
                    }),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(" 新窗口打开 "),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "openWay",
                    on: {
                      click: function ($event) {
                        _vm.urlMessage.select = "_self"
                      },
                    },
                  },
                  [
                    _c("div", {
                      class: [
                        "dot",
                        { active: _vm.urlMessage.select === "_self" },
                      ],
                    }),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(" 当前窗口打开 "),
                    ]),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.urlMessage.methods === 0,
                      expression: "urlMessage.methods===0",
                    },
                  ],
                  staticClass: "contentBox",
                },
                [
                  _c("div", { staticClass: "searchBox" }, [
                    _c("span", [_vm._v("输入查询：")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search,
                          expression: "search",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.search },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.search = $event.target.value
                        },
                      },
                    }),
                    _c("div", { staticClass: "btn" }, [_vm._v(" 查询 ")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "searchList" },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableData,
                            border: "",
                            height: "300",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "选择", width: "100" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleSelect(
                                              scope.$index,
                                              scope.row,
                                              scope
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.urlMessage.methods === 0 &&
                                                scope.row.shopNo ===
                                                  _vm.urlMessage.sign
                                                ? "已选"
                                                : "选择"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "shopName", label: "商品名称" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "shopNo",
                              label: "商品货号",
                              width: "150",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "shopSize",
                              label: "规格",
                              width: "150",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "shopPrice",
                              label: "价格",
                              width: "100",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-pagination", {
                        staticStyle: { padding: "30px 0 10px 20px" },
                        attrs: { layout: "prev, pager, next", total: 1000 },
                        on: { "current-change": _vm.pageSizeChange },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.urlMessage.methods === 1,
                      expression: "urlMessage.methods===1",
                    },
                  ],
                  staticClass: "contentBox",
                },
                [
                  _c("div", { staticClass: "searchBox handle" }, [
                    _c("span", [_vm._v("链接地址：")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.urlMessage.url,
                          expression: "urlMessage.url",
                        },
                      ],
                      staticClass: "urlInput",
                      attrs: {
                        type: "text",
                        placeholder:
                          "请输入URL链接地址（如http://www.baidu.com)",
                      },
                      domProps: { value: _vm.urlMessage.url },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.urlMessage, "url", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c("div", { staticClass: "btnBox" }, [
                _c("div", { staticClass: "yes", on: { click: _vm.addURL } }, [
                  _vm._v(" 确定 "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "no",
                    on: {
                      click: function ($event) {
                        _vm.editBoxShow = false
                      },
                    },
                  },
                  [_vm._v(" 取消 ")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }