var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "right-panel" }, [
    _vm.rightData
      ? _c(
          "div",
          { staticClass: "panel-header" },
          [
            _c("h1", [_vm._v(_vm._s(_vm.currentComponentTitle))]),
            _vm.rightData.name === "header"
              ? [
                  _c("el-divider"),
                  _c(
                    "el-form",
                    {
                      attrs: { inline: true },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "页面名称", required: "" } },
                        [
                          _c("el-input", {
                            on: { change: _vm.handleTitleChange },
                            model: {
                              value: _vm.rightData.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.rightData, "title", $$v)
                              },
                              expression: "rightData.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm.rightData
      ? _c(
          "div",
          { staticClass: "component-container" },
          [
            _c(_vm.rightData.name, {
              key: _vm.componentKey,
              tag: "component",
              attrs: { data: _vm.rightData },
              on: { setTemp: _vm.handleSetTemp },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }