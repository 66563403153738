<template>
  <div class="clearfix" :style="{height:`${heightWarp}px`}">
    <!-- 这里可以放置您的内容，例如多个子组件或元素 -->
    <div v-for="(item, index) in selectedList" :key="index" class="cardItem cube-selected" :class="styleClass"
      @click="changeActive(index)"
      :style="{border: `${item.imageUrl ? 0 : 1}px solid ${active == index ? '#1890ff' : '#ebedf0'}`}">
      <!-- <img :src="item.imageUrl" v-if="item.imageUrl" height="100%" width="100%" /> -->
      <div v-if="item.imageUrl" class="cardImg" :style="{backgroundImage:`url(${item.imageUrl})`}" />
      <div class="cube-selected-text" v-else>{{ item.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ziChanCom',
  data() {
    return {
      active: 0,
    }
  },
  model: { prop: 'value', event: 'modelValue' },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    layout: {
      type: [Number, String],
      required: true,
    }
  },
  computed: {
    heightWarp() {
      switch (this.layout) {
        case 1:
        case 2:
        case 3:
          return 110;
        case 4:
        case 5:
        case 6:
          return 230;
        default:
          return 110;
      }
    },
    styleClass() {
      switch (this.layout) {
        case 1:
          return 'one-per-row';
        case 2:
          return 'two-per-row';
        case 3:
          return 'three-per-row';
        case 4:
          return 'one-left-two-right';
        case 5:
          return 'two-left-two-right';
        case 6:
          return 'two-row-five';
        default:
          return '';
      }
    },
    //选中列表。
    selectedList: {
      get() {
        return this.value
      },
      set(e) {
        this.$emit('modelValue', e)
      }
    },
  },
  methods: {
    changeActive(index) {
      this.active = index;
      this.$emit('activeChange', index)
    },
  },
};
</script>

<style lang='scss' scoped>
.cardItem {
  overflow: hidden;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 2px 10px hsla(0, 0%, 49%, 0.16);
  border: 1px solid transparent;
  border-radius: 2px;
  cursor: pointer;
}

//一行一个
.one-per-row {
  height: 110px;
  min-height: 110px;
  width: 344px;
}

//一行两个
.two-per-row {
  height: 110px;
  min-height: 110px;
  width: 166px;
}
.two-per-row:first-child {
  top: 0;
  left: 0;
}
.two-per-row:nth-child(2) {
  top: 0;
  left: 176px;
}

//一行三个
.three-per-row {
  height: 110px;
  min-height: 110px;
  width: 108px;
}
.three-per-row:first-child {
  top: 0;
  left: 0;
}
.three-per-row:nth-child(2) {
  top: 0;
  left: 118px;
}
.three-per-row:nth-child(3) {
  top: 0;
  left: 236px;
}

//一左两右
.one-left-two-right:first-child {
  height: 228px;
  min-height: 228px;
  width: 164px;
  top: 0;
  left: 0;
}
.one-left-two-right:nth-child(2) {
  height: 110px;
  min-height: 110px;
  width: 166px;
  top: 0;
  left: 174px;
}
.one-left-two-right:nth-child(3) {
  height: 110px;
  min-height: 110px;
  width: 166px;
  top: 120px;
  left: 174px;
}

//两左两右
.two-left-two-right {
  height: 110px;
  min-height: 110px;
  width: 150px;
}
.two-left-two-right:first-child {
  top: 0;
  left: 0;
}
.two-left-two-right:nth-child(2) {
  top: 0;
  left: 160px;
}
.two-left-two-right:nth-child(3) {
  top: 120px;
  left: 0;
}
.two-left-two-right:nth-child(4) {
  top: 120px;
  left: 160px;
}

//两行五个
.two-row-five {
   height: 110px;
  min-height: 110px;
}
.two-row-five:first-child {
  width: 166px;
  top: 0;
  left: 0;
}
.two-row-five:nth-child(2) {
  width: 166px;
  top: 0;
  left: 178px;
}
.two-row-five:nth-child(3) {
  width: 108px;
  top: 120px;
  left: 0px;
}
.two-row-five:nth-child(4) {
  width: 108px;
  top: 120px;
  left: 118px;
}
.two-row-five:nth-child(5) {
  width: 108px;
  top: 120px;
  left: 236px;
}

.clearfix {
  position: relative;
  width: 375px;
  .cube-selected {
    text-align: center;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ebedf0;
    color: #7d7e80;
    cursor: pointer;
    .cardImg {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      vertical-align: baseline;
    }
    .cube-selected-text {
      font-size: 12px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
</style>