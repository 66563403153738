import { render, staticRenderFns } from "./ziChanCom.vue?vue&type=template&id=0cb34277&scoped=true"
import script from "./ziChanCom.vue?vue&type=script&lang=js"
export * from "./ziChanCom.vue?vue&type=script&lang=js"
import style0 from "./ziChanCom.vue?vue&type=style&index=0&id=0cb34277&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cb34277",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0cb34277')) {
      api.createRecord('0cb34277', component.options)
    } else {
      api.reload('0cb34277', component.options)
    }
    module.hot.accept("./ziChanCom.vue?vue&type=template&id=0cb34277&scoped=true", function () {
      api.rerender('0cb34277', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/O2OMall/config/template/decoratePage/components/rightTemplate/components/ziChanCom.vue"
export default component.exports