<template>
  <div class="right-panel">
    <!-- 标题区 -->
    <div v-if="rightData" class="panel-header">
      <h1>{{ currentComponentTitle }}</h1>

      <!-- 头部特殊处理 -->
      <template v-if="rightData.name === 'header'">
        <el-divider />
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item label="页面名称" required>
            <el-input v-model="rightData.title" @change="handleTitleChange" />
          </el-form-item>
        </el-form>
      </template>
    </div>

    <!-- 动态组件渲染区 -->
    <div class="component-container" v-if="rightData">
      <component :key="componentKey" :is="rightData.name" :data="rightData" @setTemp="handleSetTemp" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { COMPONENT_NAMES } from './constants';
import { v4 } from 'uuid';

// 自动注册 components/rightTemplate 下的所有组件
const components = {};
const requireContext = require.context(
  './rightTemplate',
  false,
  /\.vue$/
);

requireContext.keys().forEach(fileName => {
  const componentName = fileName.replace(/^\.\/(.*)\.vue$/, '$1');
  components[componentName] = requireContext(fileName).default;
});

export default {
  name: 'RightPanel',
  components,
  props: {
    // 可添加详细的 prop 校验
  },
  data() {
    return {
      componentKey: 0 // 用于强制刷新组件
    };
  },
  computed: {
    ...Vuex.mapState({
      rightData: state => state.template.rightData
    }),
    // 当前组件显示标题
    currentComponentTitle() {
      return this.rightData?.name ? COMPONENT_NAMES[this.rightData.name] || '' : '';
    }
  },
  watch: {
    // 当 rightData 变化时刷新组件
    'rightData.id': {
      handler(newVal) {
        if (newVal) {
          this.componentKey = v4();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleTitleChange() {
      this.$emit('setTemphead', this.rightData.title);
    },
    handleSetTemp(data) {
      this.$emit('setTemp', data);
    }
  }
};
</script>

<style lang="scss" scoped>
.right-panel {
  padding: 15px;
  height: 100%;
  overflow-y: auto;

  .panel-header {
    margin-bottom: 20px;

    h1 {
      font-size: 18px;
      color: #333;
    }
  }

  .component-container {
    margin-top: 15px;
  }
}
</style>