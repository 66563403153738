var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "operationFloor" }, [
    _c(
      "div",
      {
        staticClass: "imgBox",
        on: {
          mouseup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "left", 37, $event.key, [
                "Left",
                "ArrowLeft",
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 0) {
              return null
            }
            $event.stopPropagation()
            return _vm.changeStop()
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.caseShow,
                  expression: "caseShow",
                },
              ],
              staticClass: "area",
              style: {
                width: _vm.areaWidth + "px",
                height: _vm.areaHeight + "px",
                left: _vm.starX + "px",
                top: _vm.starY + "px",
              },
            }),
            _vm._l(_vm.areaData, function (item, index) {
              return _c("AreaBox", {
                key: index,
                attrs: { id: index, "area-init": item },
                on: {
                  "update:areaInit": function ($event) {
                    item = $event
                  },
                  "update:area-init": function ($event) {
                    item = $event
                  },
                  DelAreaBox: _vm.DelAreaBox,
                  addURL: _vm.addURL,
                },
              })
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }