<template>
    <!-- 右边控制商品样式组件 -->
    <div class="goods">
        <el-form :model="data">
            <div class="x-bc marB10">
                <div class="x-f">
                    <span class="marR20 label">添加优惠券</span>
                </div>
                <!-- <div class="x-f btnListBox">
                    <el-radio v-model="data.style.addManner" label="1">手动添加</el-radio>
                    <el-radio v-model="data.style.addManner" label="2">自动添加</el-radio>
                </div> -->
            </div>
            <div class="couponBox">
                <div v-if="data.style.addManner == 1">
                    <div class="selectGoods x-f"  @click="showCoupon = true">
                        <div class="select-btn">
                            添加优惠券
                            <span class="select-num">({{ data.couponList.length < 1 ? 0 : data.couponList.length }}/100)</span>
                        </div>
                    </div>
                </div>
               <div v-else-if="data.addManner == 2">
                   <div class="marB20">券活动数</div>
                   <div class="x-f marB20">
                       <el-radio v-model="data.style.couponNumMode" label="1">全部</el-radio>
                       <el-radio v-model="data.style.couponNumMode" label="2">
                           <el-input style="width: 150px" size="mini" placeholder="请输入显示的券数量"
                                     v-model="data.style.couponNum"
                           ></el-input>
                       </el-radio>
                   </div>
                   <div class="x-bc">
                       <div class="x-f">
                           <span class="fontColorAF">隐藏不可分享的优惠券</span>
                           <span class="marL10">{{data.style.conceal ? '隐藏' : '不隐藏'}}</span>
                       </div>
                       <el-checkbox v-model="data.conceal"></el-checkbox>
                   </div>
               </div>
            </div>
             <div class="control-group">
                <div class="control-group__header">优惠券列表</div>
                <el-table :data="data.couponList" height="300px" style="width: 100%">
                    <el-table-column align="center" prop="couponCaseNo" label="优惠券方案编号" show-overflow-tooltip />
                    <el-table-column align="center" prop="couponCaseName" label="优惠券方案名称" show-overflow-tooltip />
                    <!-- <el-table-column prop="couponCaseType" label="优惠券类型" show-overflow-tooltip> <template slot-scope="scope">
                    <dict-tag
                      :options="dict.type.coupon_case_type"
                      :value="scope.row.couponCaseType"
                    />
                  </template></el-table-column> -->
                    <el-table-column align="center" prop="couponDesc" label="优惠券内容" show-overflow-tooltip />
                    <el-table-column align="center" prop="date" label="操作" width="80">
                        <template slot-scope="scope">
                            <span  @click="handleClick(scope.row, scope.$index)" style="color: red;">删除</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分割线 -->
            <el-divider></el-divider>
            <div class="control-group">
               <div class="x-f">
                   <div class="control-group__header fontColorAF">列表样式</div>
                   <div class="marL10 ">{{ listModeText }}</div>
               </div>
                <div class="control-group__content">
                    <div class="list-radio x-f">
                        <div
                            class="item-radio cursorP x-c"
                            v-for="(item, index) in listStyle"
                            :key="item.id"
                            :class="{ active: data.style.curListStyleIndex === index }"
                            @click="clickListStyle(item, index)"
                        >
                            <div class="icon x-c" v-if="(data.style.curCardStyleIndex==4&&(index==1||index==2))?false:true">
                                <iconpark-icon :name="item.icon" size="18px"></iconpark-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="control-group">
                <div class="x-f">
                    <div class="control-group__header fontColorAF">卡片样式</div>
                    <div class="marL10 ">{{ cardModeText }}</div>
                </div>
                <div class="control-group__content">
                    <div class="list-radio x-w">
                        <div
                            class="item-radio cursorP x-c"
                            v-for="(item, index) in cardList"
                            :key="item.id"
                            :class="{ active: data.style.curCardStyleIndex === index }"
                            @click="clickCardStyle(item, index)"
                        >
                            <div class="icon x-c"  v-if="((data.style.curListStyleIndex==1||data.style.curListStyleIndex==2)&&index<4)||(data.style.curListStyleIndex==0||data.style.curListStyleIndex==3)">
                                <iconpark-icon :name="item.icon" size="18px"></iconpark-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="control-group x-bc">
                <div class="x-f">
                    <div class="control-group__header fontColorAF">颜色</div>
                    <div class="marL10 ">{{ colorText }}</div>
                </div>
                <div class="control-group__content">
                    <div class="list-radio x-f">
                        <div
                            class="colorLumpBox cursorP "
                            v-for="(item, index) in colorList"
                            :key="item.id"
                            :class="{ active: colorItem === index }"
                            @click="clickColorStyle(item, index)"
                        >
                            <div class="colorLump x-c" :style="{backgroundColor: item.bgColor}"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="x-bc marT10 marB10">
                <div class="x-f">
                    <span class="fontColorAF">隐藏已抢完及失效的券</span>
                    <span class="marL10">{{data.style.concealDisabled ? '隐藏' : '不隐藏'}}</span>
                </div>
                <el-checkbox v-model="data.style.concealDisabled"></el-checkbox>
            </div>
            <span class="fontColorAF">当页面无可显示的优惠券时，优惠券区块将隐藏</span>
<!--            ----------------------------- ↑ new ------------------------------------- -->
        </el-form>
        <SelectCoupon
            :multipleSelection='data.couponList'
            :OpenCoupon.sync="showCoupon"
            @getCoupons="getCoupons"
        >
        </SelectCoupon>
    </div>
</template>

<script>
/**
 * 排列样式：
 *
 * 垂直排列
 * 四宫格排列
 * 横向排列，自动换行
 * 横向滚动
 */
import SelectCoupon from '@/views/components/selectCoupon/index.vue'
export default {
    name: 'coupon',
    components: { SelectCoupon },
  //   dicts: [
  //   'coupon_case_type' //优惠类型
  // ],
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            showCoupon: false, // 选择优惠券弹框
            //列表样式数组
            listStyle: [
                { id: 0, icon: 'datuxianshi', text: '大图模式' },
                { id: 1, icon: 'lianghangpailie', text: '一行两个' },
                { id: 2, icon: 'sanhangpailie', text: '一行三个' },
                { id: 3, icon: 'hengxianggundong', text: '横向滑动' },
            ],
            listModeText: '大图模式', // 列表样式文本
            cardList: [
                { id: 0, icon: 'yangshi1', text: '样式一' },
                { id: 1, icon: 'yangshi2', text: '样式二' },
                { id: 2, icon: 'yangshi3', text: '样式三' },
                { id: 3, icon: 'yangshi4', text: '样式四' },
                { id: 4, icon: 'yangshi8', text: '样式五' },
                // { id: 5, icon: 'yangshi5', text: '样式五' },
                // { id: 6, icon: 'yangshi6', text: '样式六' },
                // { id: 7, icon: 'yangshi7', text: '样式七' },
                // { id: 8, icon: 'yangshi8', text: '样式八' }
            ],
            cardModeText: '样式一', // 卡片样式文本
            colorList: [
                { id: 1, bgColor: '#ff3c29', textColor: '#ffffff', text: '红色' },
                { id: 2, bgColor: '#fde8b6', textColor: '#f39343', text: '黄色' },
                { id: 3, bgColor: '#fdfdfe', textColor: '#d45134', text: '白色' },
                { id: 4, bgColor: '#383b3e', textColor: '#ffffff', text: '黑色' },
                { id: 5, bgColor: '#ddf1e1', textColor: '#4cac5a', text: '绿色' },
            ],
            colorText: '红色', // 颜色文本
            colorItem: 0, // 颜色选择小标

        }
    },
    watch: {
        //侦听购物车样式 是否显示自定位购物车按钮文字
        'data.curShoppingCardIndex': {
            handler(val) {
                this.isDiyBtnText =
                    val === 4 || val === 5 || val === 6 || val === 7 ? true : false
            },
            immediate: true
        }
    },
    methods: {
        handleClick (row, index) {
            this.data.couponList.splice(index, 1);
        },
        //传送控制中间商品样式数据
        templateMsg() {
            this.$emit('setTemp', this.data)
        },
        //点击列表样式事件
        clickListStyle(item, index) {
            console.log('下标：', item.id)
            // this.data.style.curListStyleIndex = index
            this.$set(this.data.style, 'curListStyleIndex', item.id)
            this.listModeText = item.text
            this.templateMsg()
        },
        //点击商品卡片样式事件
        clickCardStyle(item, index) {
            this.data.style.curCardStyleIndex = item.id
            this.cardModeText = item.text
            this.templateMsg()
        },
        // 颜色选择
        clickColorStyle(item, index){
            this.data.style.couponColor = item.bgColor
            this.data.style.textColor = item.textColor
            this.data.style.colorId = item.id
            this.colorText = item.text
            this.colorItem = index
            this.templateMsg()
        },
        // 选择优惠券
        getCoupons(val) {
            console.log('选择的优惠券：', val)
            this.data.couponList = this.data.couponList.filter(
                item => (!(JSON.stringify(item) === '{}') && !(item.couponCaseId === undefined)) ||
                    !(item.couponCaseId === undefined)
            )

            this.data.couponList=val
            this.showCoupon = false
        },
    }
}
</script>

<style lang="scss" scoped>

.couponBox {
    width: 100%;
    background-color: #f6f7f9;
    padding: 10px;
}

//标题样式
.module-title {
    font-weight: 500;
    font-size: 16px;
    padding: 10px 0;
    color: #333;
}

//选择商品按钮样式
.select-btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: rgba(21, 91, 212, 0.03);
    border: 1px dashed rgba(21, 91, 212, 0.3);
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 12px;
    color: #155bd4;
    border-collapse: collapse;

    .select-num {
        color: #999;
        font-size: 12px;
    }
}

//分割线样式
.el-divider {
    margin: 10px 0;
}

//每一组控制
.control-group {
    margin-bottom: 15px;

    .control-group__header {
        font-size: 14px;
    }
}

//列表样式
.list-radio {
    margin-top: 10px;

    .colorLumpBox{
        padding: 3px;
        border: 1px solid #d2d2d2;
        border-radius: 50px;
        margin-right: 10px;

        .colorLump{
            width: 15px;
            height: 15px;
            border-radius: 50px;

        }
        //焦点
        &.active {
            border-color: #155bd4;
        }
    }

    .text {
        color: #999;
    }

    .item-radio {
        text-align: center;

        .icon {
            //width: 30px;
            height: 32px;
            border: 1px solid #e0e0e0;
            padding: 0 16px;
            //margin-bottom: 8px;
        }

        //焦点
        &.active {
            color: #155bd4;
        }

        &.active .icon {
            background: #edf4ff;
            border-color: #155bd4;
        }

        &.active .text {
            color: #155bd4;
        }
    }
}

</style>
