import { render, staticRenderFns } from "./AreaBox.vue?vue&type=template&id=3a25da75&scoped=true"
import script from "./AreaBox.vue?vue&type=script&lang=js"
export * from "./AreaBox.vue?vue&type=script&lang=js"
import style0 from "./AreaBox.vue?vue&type=style&index=0&id=3a25da75&scoped=true&lang=scss&ref=stylesheet%2Fscss"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a25da75",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a25da75')) {
      api.createRecord('3a25da75', component.options)
    } else {
      api.reload('3a25da75', component.options)
    }
    module.hot.accept("./AreaBox.vue?vue&type=template&id=3a25da75&scoped=true", function () {
      api.rerender('3a25da75', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/O2OMall/config/template/decoratePage/components/rightTemplate/components/AreaBox.vue"
export default component.exports